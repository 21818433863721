import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import HeroBackground from '../molecules/HeroBackground';
import WomanBackground from '../../static/images/mulher-camisa-roxa.png';
import ShowInMobile from '../atoms/ShowInMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    '& .span': {
      width: '70%',
      color: `${theme.palette.primary.light} !important`,
    },
    
    '& .title strong': {
      color: '#8645FF',
    },

    '@media screen and (max-width: 959px)': {
      '& .span': {
        width: '100%',
        color: `${theme.palette.primary.light} !important`,
      },
    },
  },
  description: {
    maxWidth: '390px',
    color: `${theme.palette.primary.light} !important`,
  },
  picture: {
    position: 'relative',

    '& img': {
      width: '100%',
      position: 'absolute',
      bottom: '-8px',
    },

    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  }
}));

const HomeHero = () => {
  const classes = useStyles();

  return (
    <HeroBackground page="solucoes">
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={6} xs={12} className="text">
          <Typography className="span">
            O futuro é criado por experiências inovadoras
          </Typography>

          <Typography variant="h3" component="h1" className="title">
            Unimos <strong>tecnologia e</strong><br />
            <strong>inteligência</strong> para oferecer o<br />
            melhor serviço de contabilidade
          </Typography>

          <Typography variant="body1" component="h2" className={classes.description}>
            Entenda como simplificamos a contabilidade de milhares de empresas todos os dias no Brasil
          </Typography>
        </Grid>

        <ShowInMobile breakpoint={959}>
          <Grid item md={6} xs={12} className={classes.picture}>
            <img src={WomanBackground} alt="Robô com telas de hologramas em volta" />
          </Grid>
        </ShowInMobile>
      </Grid>
    </HeroBackground>
  );
};

export default HomeHero;
