import React, { useEffect } from 'react';
import Main from '../components/pages/Main';
import Solutions from '../components/pages/Solutions';

const QuantoCusta = () => {
  return (
    <Main page="solucoes">
      <Solutions />
    </Main>
  );
}

export default QuantoCusta;
